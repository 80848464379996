<template>
  <div class="home">
    <el-container>
      <!-- 页头 -->
      <el-header>
        <div class="title">
          <div class="image" @click="shouye">
            <el-carousel
              height="80px"
              direction="vertical"
              arrow="never"
              indicator-position="none"
              :interval="2000"
            >
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo.png" />
              </el-carousel-item>
              <el-carousel-item>
                <img src="../assets/img/logo2.png" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="titleBtn">
            <div class="active" @click="shouye">首页</div>
            <div @click="shanpin">产品中心</div>
            <div @click="zixun">资讯动态</div>
            <div @click="zhaoxian">招贤纳士</div>
            <div @click="yuedong">走进跃动</div>
            <div @click="lianxi">联系我们</div>
          </div>
        </div>
      </el-header>
      <!-- 主体 -->
      <el-main>
        <div>
          <!-- 轮播图 -->
          <div class="homemaiin">
            <el-carousel trigger="click" height="640px" arrow="never">
              <el-carousel-item v-for="(item, i) in carousel" :key="i">
                <div
                  class="divback"
                  :style="{ 'background-image': 'url(' + item.img + ')' }"
                  @click="lubo(item.lianj)"
                ></div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- 客户服务体系 -->
          <div class="homeback">
            <div class="til">打造全方位智能化场所客户服务体系</div>
            <div class="min">
              为上网服务营业场所提供安全计费、便捷上网、大数据营销等服务
            </div>
            <div class="card">
              <!-- 正式跳转地址 www.8quan ,测试跳转地址 test.8quan -->
              <div
                class="checked"
                @click="tiaozhuan(`http://www.8quan.com/#/`)"
              >
                <img src="../assets/img/card1.png" />
                <div class="tal">8圈智能场所管理平台</div>
                <div class="tel"></div>
                <div class="info">
                  为上网服务营业场所量身打造的智能管理系统，提供全方位的管理、营销和增值服务方案，为场所提供专属智能管理服务。
                </div>
                <div class="btn">
                  查看详情<img src="../assets/img/right.png" />
                </div>
              </div>
              <div
                class="checked"
                @click="tiaozhuan(`http://www.8quan.com/#/robot0`)"
              >
                <img src="../assets/img/card2.png" />
                <div class="tal">8圈机器人</div>
                <div class="tel">8圈智能场所自助服务系统</div>
                <div class="info">
                  为了提升场所智慧经营，提高场所收入，精心打造的一款集AI人脸识别、无人收银系统、网民自助上网、智能财务管理等功能于一体的智能自助服务设备，与8圈计费完美结合，实现数据共享，统一云端存储，信息管理更安全。
                </div>
                <div class="btn">
                  查看详情<img src="../assets/img/right.png" />
                </div>
              </div>
              <div
                class="checked"
                @click="tiaozhuan(`http://www.8quan.com/#/eagleEye0`)"
              >
                <img src="../assets/img/card1.png" />
                <div class="tal">8圈鹰眼</div>
                <div class="tel">8圈智能场所会员识别系统</div>
                <div class="info">
                  8圈鹰眼系统应用AI技术对场所内人员自动识别，并进行会员信息识别认证，为会员提供个性化服务，同时去上网APP接收到相关信息，实现快速上网。
                </div>
                <div class="btn">
                  查看详情<img src="../assets/img/right.png" />
                </div>
              </div>
              <div
                class="checked"
                @click="tiaozhuan(`http://www.8quan.com/#/palm0`)"
              >
                <img src="../assets/img/card1.png" />
                <div class="tal">掌上8圈APP</div>
                <div class="tel">智能场所经营状况实时查询软件</div>
                <div class="info">
                  专为上网服务营业场所经营者提供实时查询经营状况的助手APP。提供场所经营数据统计、消息推送、手机提现、行业资讯等服务，满足管理者随时随地了解场所经营情况的需求。
                </div>
                <div class="btn">
                  查看详情<img src="../assets/img/right.png" />
                </div>
              </div>
              <div
                class="checked"
                @click="tiaozhuan(`http://www.8quan.com/#/gonetwork0`)"
              >
                <img src="../assets/img/card3.png" />
                <div class="tal">去上网APP</div>
                <div class="tel">便捷上网系统</div>
                <div class="info">
                  一款以方便网民、简化上网流程、降低场所经营成本为理念所开发的移动APP，为网民提供便捷、安全、有效的上网方式。
                </div>
                <div class="btn">
                  查看详情<img src="../assets/img/right.png" />
                </div>
              </div>
            </div>
          </div>
          <!-- 业务分布 -->
          <div class="businessback">
            <div class="bor">
              <div class="province">
                <div class="distribute">业务分布</div>
                <div class="dizhi">
                  业务覆盖重庆、四川、宁夏、海南、陕西、河南、内蒙古、甘肃、云南等八省一市，产品广泛运用于电竞馆、电竞酒店、民宿等场所。
                </div>
                <div class="shuju">
                  <div style="opacity: 1">2000W+</div>
                  <div>服务人群</div>
                </div>
                <div class="shuju">
                  <div>100W+</div>
                  <div>终端</div>
                </div>
              </div>
              <!-- <div class="map"><img src="../assets/img/map.png" /></div> -->
            </div>
          </div>
          <!-- 资讯动态 -->
          <div class="dynamic">
            <div class="dynamicCard">
              <div class="dynamicTil">资讯动态</div>
              <div class="dynamicka">
                <div
                  class="dynamickad"
                  v-for="item of data"
                  :key="item.Id"
                  @click="tiaoz(item.Id)"
                >
                  <img :src="'https://' + item.imgUrl" />
                  <div>{{ item.newsTitle }}</div>
                  <div>{{ item.guide }}</div>
                  <div class="date">
                    <div>{{ item.timeDate }}</div>
                    <img src="../assets/img/right2.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 合作伙伴 -->
          <div class="partner">
            <div class="partnercard">
              <div>合作伙伴</div>
              <div class="partnerImg">
                <img src="../assets/img/card7.png" />
                <img src="../assets/img/card8.png" />
                <img src="../assets/img/card9.png" />
                <img src="../assets/img/card10.png" />
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <!-- 底部引入组件 -->
      <footerComponents></footerComponents>
    </el-container>
  </div>
</template>

<script>
import footerComponents from "../components/footer.vue";
export default {
  name: "Home",
  components: { footerComponents },
  data() {
    return {
      //轮播图图片
      carousel: [
        {
          img: require("../assets/img/banner1.png"),
          lianj: "http://www.8quan.com/#/robot0",
        },
        {
          img: require("../assets/img/banner2.png"),
          lianj: "http://www.8quan.com/#/eagleEye0",
        },
        {
          img: require("../assets/img/banner3.png"),
          lianj: "http://www.8quan.com/#/download",
        },
      ],
      data: [],
    };
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPod|BlackBerry|Harmony/i.test(navigator.userAgent)
    ) {
      //移动设备打开的网页，缩放页面尺寸
      /*
      var clientWidth = document.body.clientWidth;
      var rate = clientWidth / 1920;
      */
      var rate = 0.1;
      var meta = document.getElementsByTagName("meta");
      meta["viewport"].setAttribute(
        "content",
        "width=device-width,initial-scale=" + rate
      );
    }
    this.$http.get("/api/three").then((res) => {
      let a = JSON.parse(res.data);
      console.log(a[0]);
      if (a[0].code == "success") {
        this.data = a[0].data;
      }
    });
  },
  methods: {
    //跳转首页
    shouye() {
      this.$router.push("/");
    },
    //跳转产品中心
    shanpin() {
      this.$router.push("/product");
    },
    //跳转资讯动态
    zixun() {
      this.$router.push("/information");
    },
    //跳转招贤纳士
    zhaoxian() {
      this.$router.push("/careers");
    },
    //跳转走进跃动
    yuedong() {
      this.$router.push("/yedone");
    },
    //联系我们
    lianxi() {
      this.$router.push("/contactUs");
    },
    //跳转详情页
    tiaoz(id) {
      this.$router.push({ name: "Dynamic", query: { id: id } });
    },
    //跳转产品
    tiaozhuan(e) {
      window.open(e);
    },
    //轮播图跳转
    lubo(e) {
      window.open(e);
    },
  },
};
</script>
<style lang="less" scoped>
// 页头样式
.el-header {
  width: 100%;
  min-width: 1480px;
  padding: 0;
  height: 80px !important;
  line-height: 80px;
  margin: 0;
  .title {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    .image {
      min-width: 158px;
      height: 80px;
      margin-right: 52px;
      img {
        width: 158px;
        height: 40px;
        padding-top: 20px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .titleBtn {
      display: flex;
      align-items: center;
      height: 80px;
      box-sizing: border-box;
      > div {
        margin-left: 68px;
        width: 72px;
        height: 27px;
        font-size: 18px;
        font-family: Source Han Sans SC;
        font-weight: 500;
        line-height: 27px;
        color: #666666;
        text-align: center;
        &:hover {
          cursor: pointer;
        }
      }
      .active {
        position: relative;
        color: #004097;
      }
      .active::after {
        position: absolute;
        bottom: -10px;
        content: "";
        width: 30px;
        height: 4px;
        background: #004097;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
}

.el-main {
  min-width: 1480px;
  width: 100%;
  padding: 0;
  margin: 0 auto;
}
.homemaiin {
  width: 100%;
  height: 640px;
  margin: 0 auto;
  // 轮播图总体大小
  /deep/.el-carousel--horizontal {
    min-width: 1480px;
    margin: 0 auto;
    // 增加每个轮播图大小，避免出现白色间隙
    .el-carousel__item{
      width: calc(~'100% + 2px');
    }
    .divback {
      height: 100%;
      background-size: cover;
      /* 将背景图片等比缩放填满整个容器 */
      background-repeat: no-repeat;
      background-position: center;
      &:hover {
        cursor: pointer;
      }
    }
  }

  /* 轮播图指示器样式 */
  /deep/.el-carousel__indicators--horizontal {
    li {
      padding: 40px 12px;
    }
    button {
      width: 40px;
      height: 8px;
      background: #ffffff;
      border-radius: 4px;
    }
  }
}

.homeback {
  height: 620px;
  background-image: url(../assets/img/homeback.png);
  background-size: cover;
  /* 将背景图片等比缩放填满整个容器 */
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 80px 0 0;
  font-family: Source Han Sans SC;
  text-align: center;

  .til {
    width: 761px;
    height: 54px;
    font-size: 36px;
    font-weight: bold;
    line-height: 54px;
    color: #161e2a;
    margin: 0 auto;
  }
  .min {
    width: 761px;
    height: 27px;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    color: #858d9e;
    margin: 20px auto 0;
  }
  .card {
    width: 1480px;
    height: 320px;
    margin: 40px auto 0;
    display: flex;
    justify-items: center;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
    }

    .checked {
      width: 280px;
      height: 320px;
      background: #ffffff;
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;
      padding-top: 80px;
      font-family: Source Han Sans SC;
      > img {
        transition-duration: 1s;
      }
      .tal {
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #161e2a;
        margin: 20px auto 0;
        transition-duration: 1s;
      }
      .tel {
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #8c97a8;
        margin: 8px auto 0;
        transition-duration: 1s;
      }
      .info {
        width: 220px;
        height: 83px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #626778;
        margin: 8px auto 0;
        opacity: 0;
        transition-duration: 1s;
        //多行文本溢出显示省略号点点点…
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4; //这里是在第4行有省略号
        overflow: hidden;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: #004097;
        margin: 25px auto 0;
        display: flex;
        opacity: 0;
        justify-items: center;
        justify-content: center;
        transition-duration: 1s;

        > img {
          margin-left: 4px;
        }
      }
      //动画
      &:hover {
        border: 2px solid #004097;
        > img {
          transform: translate(0, -50px);
        }
        .tal {
          transform: translate(0, -50px);
        }
        .tel {
          transform: translate(0, -50px);
          opacity: 0;
          visibility: hidden;
        }
        .info {
          opacity: 0.68;
          transform: translate(0, -72px);
        }
        .btn {
          opacity: 1;
          transform: translate(0, -72px);
        }
      }
    }
  }
}
.businessback {
  height: 740px;
  background-image: url(../assets/img/homeback2.png);
  background-size: cover;
  /* 将背景图片等比缩放填满整个容器 */
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  padding-top: 102px;
  margin: 0 auto;
  .bor {
    width: 1200px;
    height: 537px;
    margin: 0 auto;
    display: flex;
    .province {
      color: #ffffff;
      font-family: Source Han Sans SC;
      .distribute {
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
      }
      .dizhi {
        width: 436px;
        height: 91px;
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        margin: 20px 32px 10px 0;
      }
      .shuju {
        width: 320px;
        height: 120px;
        border: 2px solid #485e8a;
        border-radius: 12px;
        margin-top: 30px;
        padding: 26px 0 0 30px;
        box-sizing: border-box;
        div:nth-child(1) {
          font-size: 36px;
          font-family: DIN;
          font-weight: 400;
          line-height: 36px;
        }
        div:nth-child(2) {
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          color: #8c97a8;
          margin-top: 10px;
        }
      }
    }
    .map {
      width: 732px;
      height: 537px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.dynamic {
  height: 720px;
  background-image: url(../assets/img/homeback3.png);
  background-size: cover;
  /* 将背景图片等比缩放填满整个容器 */
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-top: 84px;
  .dynamicCard {
    width: 1200px;
    height: 556px;
    margin: 0 auto;
    .dynamicTil {
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 36px;
      color: #161e2a;
      text-align: center;
    }
    .dynamicka {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      .dynamickad {
        width: 380px;
        height: 462px;
        background: #ffffff;
        border-radius: 12px;
        box-sizing: border-box;
        &:hover {
          cursor: pointer;
          box-shadow: 0px 5px 20px rgba(90, 97, 107, 0.1);
        }
        > img {
          width: 380px;
          height: 253px;
          border-radius: 12px 12px 0 0;
        }
        div:nth-child(2) {
          width: 340px;
          height: 29px;
          font-size: 20px;
          font-family: Source Han Sans SC;
          font-weight: 500;
          line-height: 30px;
          color: #161e2a;
          margin: 20px auto 0;
          white-space: nowrap; //文本强制不换行；
          text-overflow: ellipsis; //文本溢出显示省略号；
          overflow: hidden; //溢出的部分隐藏；
        }
        div:nth-child(3) {
          width: 340px;
          height: 80px;
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          line-height: 28px;
          color: #626778;
          margin: 10px auto 0;
          display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
          -webkit-box-orient: vertical; //设置或检索伸缩盒对象的子元素的排列方式
          -webkit-line-clamp: 3; //用来限制在一个块元素显示的文本的行数
          overflow: hidden; //溢出的部分隐藏；
        }
        .date {
          display: flex;
          justify-content: space-between;
          align-content: center;
          width: 340px;
          height: 20px;
          margin: 20px auto 0;
          div {
            font-size: 14px;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: #8c97a8;
          }
          > img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
// 合作伙伴
.partner {
  height: 366px;
  padding-top: 80px;
  box-sizing: border-box;
  .partnercard {
    width: 1200px;
    height: 206px;
    margin: 0 auto;
    div:nth-child(1) {
      text-align: center;
      font-size: 36px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      line-height: 36px;
      color: #161e2a;
    }
    .partnerImg {
      display: flex;
      justify-content: space-between;
      margin-top: 41px;
    }
  }
}
</style>
