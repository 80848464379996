import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/product',
    name: 'Product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "product" */ '../views/product.vue')
  },
  {
    path: '/information',
    name: 'Information',
    component: () => import(/* webpackChunkName: "information" */ '../views/information.vue')
  },
  {
    path: '/dynamic',
    name: 'Dynamic',
    component: () => import(/* webpackChunkName: "dynamic" */ '../components/dynamic.vue')
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import(/* webpackChunkName: "careers" */ '../views/careers.vue')
  },
  {
    path: '/yedone',
    name: 'Yedone',
    component: () => import(/* webpackChunkName: "yedone" */ '../views/yedone.vue')
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: () => import(/* webpackChunkName: "contactUs" */ '../views/contactUs.vue')
  },
  {
    path: '/yinye',
    name: 'yinye',
    component: () => import(/* webpackChunkName: "yinye" */ '../views/yinye.vue')
  },
]



const router = new VueRouter({
  routes,
  //刷新页面时回顶部
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0}
  }
})

export default router
