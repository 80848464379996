<template>
  <div id="app">
    <div class="goTop" @click="goTop">
      <img src="../src/assets/img/goTop.png">
    </div>
    <!-- 路由跳转页面不刷新，加随机key -->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>
<script>
export default {
  methods: {
    //回到顶部
    goTop(){
      this.$scrollTo()
    }
  },
}
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
#app{width: 100%;height: 100vh;}
/* 返回顶部样式 */
#app .goTop{
  position: fixed;
  bottom: 48px;
  right: 24px;
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, .5);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#app .goTop:hover{
  cursor: pointer;
  background: rgba(0, 0, 0, 1);
}
#app .goTop img{
  width: 20px;
  height: 20px;
}
/*隐藏滚轮*/
/* *::-webkit-scrollbar {
  display: none;
} */
</style>
