<template>
  <div>
    <el-footer>
      <div class="fotter">
        <div class="fotterdiv">
          <div class="yuedong">
            <img src="../assets/img/yuedong.png" />
            <div class="text1">
              © Copyright 2008 yedone.com all rights reserved.
              渝网文[2013]0887-006号
            </div>
            <div class="text2">
              经营许可证：渝B2-20190234&nbsp;&nbsp;|&nbsp;&nbsp;<span @click="zhizhao">营业执照</span><br />渝ICP备09014244号-16
            </div>
            <div class="text3">
              <img src="../assets/img/guohui.png" />
              <div class="text3tel" @click="beian">渝公网安备 50010802002169号</div>
            </div>
          </div>
          <div class="bor">
            <div class="bor1"></div>
            <div class="bor2"></div>
          </div>
          <div class="contactUs">
            <div class="contactUstitle">联系我们</div>
            <div class="phone">
              <div class="dianhua1">
                <div>客服电话（24h）</div>
                <div>400-680-8811</div>
              </div>
              <div class="dianhua2">
                <div>商务电话（工作日09:00-18:00)</div>
                <div>023-68636622</div>
              </div>
            </div>
            <div class="phone2">
              <div class="dianhua1">
                <div>客服QQ（24h）</div>
                <div>4006808811</div>
              </div>
              <div class="dianhua2">
                <div>公司地址</div>
                <div>重庆市南岸区南城大道199号正联大厦3楼</div>
              </div>
            </div>
          </div>
          <div class="attention">
            <div class="attentiontitle" style="marginLeft:10px">关注我们</div>
            <div class="erweima">
              <div class="fuwu">
                <img src="../assets/img/qushangwang.png" />
                <div>去上网APP微信服务号</div>
              </div>
              <div class="gongzong">
                <img src="../assets/img/baquan.jpg" />
                <div>8圈计费微信公众号</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-footer>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    //跳转备案
    beian(){
      window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802002169")
    },
    //跳转营业执照
    zhizhao(){
      let routeData = this.$router.resolve('/yinye');
      window.open(routeData.href, '_blank');
    }
  },
};
</script>
<style lang="less" scoped>
.el-footer {
  min-width: 1480px;
  width: 100%;
  height: auto !important;
  margin: 0 auto;
  padding: 0;
}
.fotter {
  height: 338px;
  background: #0b192b;
  padding-top: 60px;
  box-sizing: border-box;
  .fotterdiv {
    width: 1200px;
    height: 216px;
    margin: 0 auto;
    display: flex;
    .yuedong {
      width: 271px;
      height: 216px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      line-height: 21px;
      color: #626778;
      .text1 {
        margin-top: 35px;
      }
      .text2 {
        margin-top: 14px;
        span:hover{cursor: pointer;color: #ffffff;}
      }
      .text3 {
        margin-top: 14px;
        display: flex;
        justify-items: center;
        img {
          margin-right: 10px;
        }
        .text3tel{
          &:hover{
            cursor: pointer;
            color: #ffffff;
          }
        }
      }
    }
    .bor {
      width: 1px;
      height: 216px;
      margin-left: 44px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .bor1 {
        flex: 1;
      }
      .bor2 {
        height: 120px;
        border: 1px solid #1b2c43;
      }
    }
    .contactUs {
      width: 475px;
      height: 218px;
      margin-left: 44px;
      font-size: 20px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #ffffff;
      .contactUstitle{
        height: 29px;
      }
      .phone,
      .phone2 {
        margin-top: 57px;
        display: flex;
        .dianhua1,
        .dianhua2 {
          div:nth-child(1) {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #626778;
          }
          div:nth-child(2) {
            height: 22px;
            font-size: 18px;
            font-family: DIN;
            color: #ffffff;
            margin-top: 8px;
          }
        }
        .dianhua1{
        width: 124px;

        }
        .dianhua2 {
          margin-left: 52px;
        }
      }
      .phone2 {
        margin-top: 30px;
        .dianhua2 div:nth-child(2) {
          font-family: Source Han Sans SC;
          font-size: 16px;
          height: 24px;
        }
      }
    }
    .attention {
      width: 298px;
      height: 218px;
      margin-left: 67px;
      font-size: 20px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #ffffff;
      .attentiontitle{
        height: 29px;
      }
      .erweima {
        display: flex;
        margin-top: 51px;
        .fuwu,
        .gongzong {
          font-size: 14px;
          font-weight: 400;
          color: #626778;
          text-align: center;
          >img{
            width: 110px;
            height: 110px;
          }
          >div{
            margin-top: 8px;
            height: 20px;
          }
        }
        .gongzong{
          margin-left: 38px;
        }
      }
    }
  }
}
</style>
